import {ValidatorFn} from '@angular/forms';

export interface FormPropertyBaseOptions<T = any, K extends keyof T & string = keyof T & string> {
    key?: K;
    label?: string;
    labelComment?: string;
    order?: number;
    disabled?: boolean;
    deprecated?: boolean;
    hint?: string;
    placeholder?: string;
    icon?: string;
    locked?: any;
    required?: boolean;
    validators?: ValidatorFn[];
}

export class FormPropertyBase<T = any, K extends keyof T & string = keyof T & string> {
    key: K;
    label: string;
    labelComment?: string;
    order: number;
    disabled?: boolean;
    deprecated?: boolean;
    hint?: string;
    placeholder?: string;
    icon?: string;
    meta?: any = {};
    locked?: any = {};
    required?: boolean;
    validators?: ValidatorFn[];

    constructor(options: FormPropertyBaseOptions<T> = {}) {
        this.key = options.key as K;
        this.label = options.label || '';
        this.labelComment = options.labelComment || '';
        this.order = options.order === undefined ? 1 : options.order;
        this.disabled = options.disabled || null;
        this.deprecated = options.deprecated || null;
        this.hint = options.hint || null;
        this.placeholder = options.placeholder || null;
        this.icon = options.icon || null;
        this.locked = options.locked || null;
        this.required = !!options.required;
        this.validators = options.validators;
    }
}
